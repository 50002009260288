import React from "react"
import styled from "astroturf"

export const IconAnalytics = () => {
  return <Svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip4)">
      <path
        d="M73.1248 47.5C72.7927 56.2245 69.096 64.4818 62.8097 70.5406C56.5234 76.5995 48.1356 79.9895 39.4048 80C39.0733 80 38.7553 79.8683 38.5209 79.6339C38.2865 79.3995 38.1548 79.0815 38.1548 78.75V47.5H73.1248Z"
        fill="#C1BBE2"/>
      <path
        d="M38.8877 45L61.4914 22.3963C61.7259 22.1619 62.0437 22.0303 62.3752 22.0303C62.7067 22.0303 63.0245 22.1619 63.2589 22.3963C69.3025 28.402 72.8306 36.4849 73.1252 45H38.8877Z"
        fill="#C1BBE2"/>
      <path
        d="M36.7501 43.5989C36.4016 43.9466 36.1253 44.3599 35.9374 44.8149C35.7495 45.27 35.6535 45.7578 35.6551 46.2501V71.4114C31.8259 70.8466 28.176 69.4159 24.983 67.2281C21.7899 65.0403 19.138 62.1531 17.2288 58.786C15.3197 55.419 14.2037 51.6609 13.9656 47.7976C13.7276 43.9343 14.3739 40.0676 15.8553 36.4916C17.3367 32.9157 19.6142 29.7248 22.5145 27.1616C25.4148 24.5984 28.8615 22.7304 32.5925 21.6999C36.3234 20.6694 40.2402 20.5034 44.0449 21.2145C47.8497 21.9257 51.4421 23.4953 54.5489 25.8039L36.7501 43.5989Z"
        fill="#C1BBE2"/>
      <path
        d="M42.7575 10.0525L33.0725 0.3675C32.837 0.133909 32.5192 0.00196879 32.1875 0L5.625 0C4.63044 0 3.67661 0.395088 2.97335 1.09835C2.27009 1.80161 1.875 2.75544 1.875 3.75V56.875C1.875 57.8696 2.27009 58.8234 2.97335 59.5266C3.67661 60.2299 4.63044 60.625 5.625 60.625H39.375C40.3696 60.625 41.3234 60.2299 42.0266 59.5266C42.7299 58.8234 43.125 57.8696 43.125 56.875V10.9375C43.1233 10.6057 42.9913 10.2879 42.7575 10.0525ZM33.4375 4.2675L38.8575 9.6875H33.4375V4.2675ZM40.625 56.875C40.625 57.2065 40.4933 57.5245 40.2589 57.7589C40.0245 57.9933 39.7065 58.125 39.375 58.125H5.625C5.29348 58.125 4.97554 57.9933 4.74112 57.7589C4.5067 57.5245 4.375 57.2065 4.375 56.875V3.75C4.375 3.41848 4.5067 3.10054 4.74112 2.86612C4.97554 2.6317 5.29348 2.5 5.625 2.5H30.9375V10.9375C30.9375 11.269 31.0692 11.587 31.3036 11.8214C31.538 12.0558 31.856 12.1875 32.1875 12.1875H40.625V56.875Z"
        fill="#121E30"/>
      <path
        d="M9.375 22.1487H25.9375C26.269 22.1487 26.587 22.017 26.8214 21.7826C27.0558 21.5481 27.1875 21.2302 27.1875 20.8987V11.2112C27.1875 10.8797 27.0558 10.5617 26.8214 10.3273C26.587 10.0929 26.269 9.96118 25.9375 9.96118H9.375C9.04348 9.96118 8.72554 10.0929 8.49112 10.3273C8.2567 10.5617 8.125 10.8797 8.125 11.2112V20.8987C8.125 21.2302 8.2567 21.5481 8.49112 21.7826C8.72554 22.017 9.04348 22.1487 9.375 22.1487ZM10.625 12.4612H24.6875V19.6487H10.625V12.4612Z"
        fill="#121E30"/>
      <path
        d="M34.4077 33.3201H24.4502C24.1187 33.3201 23.8007 33.4518 23.5663 33.6862C23.3319 33.9206 23.2002 34.2385 23.2002 34.5701C23.2002 34.9016 23.3319 35.2195 23.5663 35.454C23.8007 35.6884 24.1187 35.8201 24.4502 35.8201H34.4077C34.7392 35.8201 35.0572 35.6884 35.2916 35.454C35.526 35.2195 35.6577 34.9016 35.6577 34.5701C35.6577 34.2385 35.526 33.9206 35.2916 33.6862C35.0572 33.4518 34.7392 33.3201 34.4077 33.3201Z"
        fill="#121E30"/>
      <path
        d="M23.2002 27.1487C23.2002 27.4802 23.3319 27.7981 23.5663 28.0326C23.8007 28.267 24.1187 28.3987 24.4502 28.3987H34.4077C34.7392 28.3987 35.0572 28.267 35.2916 28.0326C35.526 27.7981 35.6577 27.4802 35.6577 27.1487C35.6577 26.8172 35.526 26.4992 35.2916 26.2648C35.0572 26.0304 34.7392 25.8987 34.4077 25.8987H24.4502C24.1187 25.8987 23.8007 26.0304 23.5663 26.2648C23.3319 26.4992 23.2002 26.8172 23.2002 27.1487Z"
        fill="#121E30"/>
      <path
        d="M34.4077 48.1638H24.4502C24.1187 48.1638 23.8007 48.2955 23.5663 48.5299C23.3319 48.7644 23.2002 49.0823 23.2002 49.4138C23.2002 49.7453 23.3319 50.0633 23.5663 50.2977C23.8007 50.5321 24.1187 50.6638 24.4502 50.6638H34.4077C34.7392 50.6638 35.0572 50.5321 35.2916 50.2977C35.526 50.0633 35.6577 49.7453 35.6577 49.4138C35.6577 49.0823 35.526 48.7644 35.2916 48.5299C35.0572 48.2955 34.7392 48.1638 34.4077 48.1638Z"
        fill="#121E30"/>
      <path
        d="M34.4077 40.7424H24.4502C24.1187 40.7424 23.8007 40.8741 23.5663 41.1085C23.3319 41.343 23.2002 41.6609 23.2002 41.9924C23.2002 42.324 23.3319 42.6419 23.5663 42.8763C23.8007 43.1107 24.1187 43.2424 24.4502 43.2424H34.4077C34.7392 43.2424 35.0572 43.1107 35.2916 42.8763C35.526 42.6419 35.6577 42.324 35.6577 41.9924C35.6577 41.6609 35.526 41.343 35.2916 41.1085C35.0572 40.8741 34.7392 40.7424 34.4077 40.7424Z"
        fill="#121E30"/>
      <path
        d="M13.0863 25.8987C12.105 25.8987 11.1458 26.1897 10.3299 26.7348C9.51406 27.28 8.87816 28.0548 8.50265 28.9613C8.12715 29.8679 8.0289 30.8654 8.22033 31.8278C8.41176 32.7902 8.88428 33.6742 9.57812 34.3681C10.272 35.0619 11.156 35.5344 12.1184 35.7259C13.0807 35.9173 14.0783 35.819 14.9848 35.4435C15.8914 35.068 16.6662 34.4321 17.2114 33.6163C17.7565 32.8004 18.0475 31.8412 18.0475 30.8599C18.0462 29.5445 17.5231 28.2834 16.5929 27.3533C15.6628 26.4231 14.4017 25.9 13.0863 25.8987ZM13.0863 33.3199C12.5994 33.3199 12.1235 33.1756 11.7187 32.905C11.3139 32.6345 10.9985 32.2501 10.8122 31.8003C10.626 31.3505 10.5773 30.8555 10.6724 30.3781C10.7675 29.9006 11.002 29.462 11.3463 29.1179C11.6907 28.7737 12.1293 28.5394 12.6069 28.4446C13.0844 28.3497 13.5793 28.3987 14.029 28.5851C14.4787 28.7716 14.863 29.0873 15.1333 29.4922C15.4036 29.8971 15.5477 30.3731 15.5475 30.8599C15.5465 31.5123 15.2868 32.1376 14.8254 32.5988C14.3641 33.0599 13.7386 33.3193 13.0863 33.3199Z"
        fill="#121E30"/>
      <path
        d="M13.0863 40.7424C12.105 40.7424 11.1457 41.0334 10.3298 41.5786C9.5139 42.1238 8.878 42.8988 8.50254 43.8054C8.12707 44.712 8.02889 45.7096 8.22042 46.672C8.41195 47.6345 8.88459 48.5185 9.57856 49.2123C10.2725 49.906 11.1567 50.3785 12.1191 50.5698C13.0816 50.761 14.0792 50.6626 14.9857 50.2869C15.8922 49.9112 16.667 49.2751 17.212 48.4591C17.757 47.6431 18.0478 46.6837 18.0475 45.7024C18.0458 44.3872 17.5226 43.1264 16.5925 42.1966C15.6624 41.2667 14.4014 40.7438 13.0863 40.7424ZM13.0863 48.1637C12.5995 48.1637 12.1236 48.0193 11.7189 47.7489C11.3141 47.4784 10.9986 47.094 10.8124 46.6443C10.6261 46.1946 10.5773 45.6997 10.6723 45.2223C10.7673 44.7448 11.0017 44.3063 11.3459 43.9621C11.6901 43.6179 12.1287 43.3834 12.6061 43.2885C13.0835 43.1935 13.5784 43.2422 14.0281 43.4285C14.4779 43.6148 14.8623 43.9303 15.1327 44.335C15.4032 44.7398 15.5475 45.2156 15.5475 45.7024C15.5468 46.355 15.2873 46.9806 14.8259 47.4421C14.3645 47.9035 13.7388 48.163 13.0863 48.1637Z"
        fill="#121E30"/>
    </g>
    <defs>
      <clipPath id="clip4">
        <rect width="80" height="80" fill="white"/>
      </clipPath>
    </defs>
  </Svg>
}

const Svg = styled.svg`
  @media (max-width: 600px){
    width: 54px;
    height: 54px;
  }
`;
