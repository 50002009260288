import styled from "astroturf"
import React from "react"
import { IconArrowRigth } from "../../icon/iconArrowRigth"
import { GatsbyPageServicesDevelopment } from "../../images/pageServices/GatsbyPageServicesDevelopment"
import { IconPoint } from "../../icon/pageServices/iconPoint"

export const SectionDevelopment = () => {
  return (
    <Section id={"development"}>
      <Container className={"container"}>
        <div>
          <Title>Разработка</Title>
          <DescriptionContainer>
            <IconPoint />
            <span>Backend</span>
            <br />
            Разработаем гибкую, масштабируемую и отказоустойчивую архитектуру
            <br />
            <a
              href={"/Service/BackDevelopment/"}
              aria-label={"BackDevelopment"}
            >
              Подробнее <IconArrowRigth color={"#2669CC"} width={"40px"} />
            </a>
          </DescriptionContainer>
          <DescriptionContainer>
            <IconPoint />
            <span>Frontend</span>
            <br />
            Создадим адаптивные сайты с богатой функциональностью
            <br />
            <a
              href={"/Service/WebDevelopment/"}
              aria-label={"WebDevelopment"}
            >
              Подробнее <IconArrowRigth color={"#2669CC"} width={"40px"} />
            </a>
          </DescriptionContainer>
          <DescriptionContainer>
            <IconPoint />
            <span>Мобильная</span>
            <br />
            Разработаем мобильные приложения IOS и Android
            <br />
            <a href={"/"} aria-label={"Dex"}>
              Подробнее <IconArrowRigth color={"#2669CC"} width={"40px"} />
            </a>
          </DescriptionContainer>
        </div>

        <ImageContainer>
          <GatsbyPageServicesDevelopment />
          <MobileBackgroundCube />
        </ImageContainer>
      </Container>
      <BackgroundCube />
    </Section>
  )
}

const Section = styled.section`
  position: relative;
  padding: 85px 0;

  @media (max-width: 991px) {
    padding-bottom: 0;
    padding-top: 40px;
  }

  @media (max-width: 420px) {
    padding: 40px 0 0 0;
  }
`
const BackgroundCube = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  left: auto;
  bottom: auto;
  z-index: -1;
  width: 35vw;
  height: 100%;
  background: #2669cc;
  opacity: 0.05;

  @media (max-width: 991px) {
    display: none;
  }
`

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 80px;
  align-items: center;

  @media (max-width: 991px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: unset;

    img {
      height: 320px;
      margin: auto;
    }
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    img {
      height: auto;
      width: 100%;
    }
  }
`

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  :global(.gatsby-image-wrapper) {
    width: 100%;
  }

  @media (max-width: 991px) {
    margin-bottom: 40px;
    padding: 20px 0;
    justify-content: center;

    :global(.gatsby-image-wrapper) {
      width: 420px;
    }
  }

  @media (max-width: 600px) {
    margin-bottom: 0;
    :global(.gatsby-image-wrapper) {
      width: 100%;
    }
  }
`

const MobileBackgroundCube = styled.div`
  position: absolute;
  background: #2669cc;
  opacity: 0.05;
  top: 0;
  bottom: 0;
  right: -30px;
  left: -30px;

  @media (min-width: 992px) {
    display: none;
  }
`

const Title = styled.h1`
  font-family: "Gilroy", sans-serif;
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
  color: #121e30;

  margin-bottom: 50px;

  @media (max-width: 600px) {
    font-size: 30px;
    line-height: 36px;
  }

  @media (max-width: 355px) {
    font-size: 26px;
  }
`

const DescriptionContainer = styled.div`
  position: relative;
  font-family: "Gilroy", sans-serif;
  font-size: 24px;
  line-height: 36px;
  color: #121e30;

  margin-bottom: 30px;

  span {
    font-weight: 500;
    font-size: 28px;
    margin-bottom: 12px;
  }
  a {
    cursor: pointer;
    font-family: "Gilroy", sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #2669cc;
    display: flex;
    align-items: center;
    margin-top: 10px;
    width: fit-content;

    svg {
      margin-left: 10px;
    }
  }

  @media (max-width: 600px) {
    font-size: 18px;
    line-height: 24px;

    span {
      font-size: 24px;
      line-height: 36px;
    }
  }

  padding-left: 44px;
`
