import React from "react"
import styled from "astroturf"

export const IconDesign = () => {
  return <Svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip1)">
      <path
        d="M48.6475 51.6162C48.8819 51.3819 49.1998 51.2503 49.5312 51.2503C49.8627 51.2503 50.1806 51.3819 50.415 51.6162L62.89 64.0912L69.1162 57.8662C69.3507 57.6319 69.6685 57.5003 70 57.5003C70.3315 57.5003 70.6493 57.6319 70.8838 57.8662L80 66.9837V43.75C80 42.7554 79.6049 41.8016 78.9016 41.0984C78.1984 40.3951 77.2446 40 76.25 40H45.625C44.6304 40 43.6766 40.3951 42.9734 41.0984C42.2701 41.8016 41.875 42.7554 41.875 43.75V58.3887L48.6475 51.6162ZM70 46.25C70.7417 46.25 71.4667 46.4699 72.0834 46.882C72.7001 47.294 73.1807 47.8797 73.4645 48.5649C73.7484 49.2502 73.8226 50.0042 73.6779 50.7316C73.5333 51.459 73.1761 52.1272 72.6516 52.6516C72.1272 53.1761 71.459 53.5332 70.7316 53.6779C70.0042 53.8226 69.2502 53.7484 68.5649 53.4645C67.8797 53.1807 67.294 52.7001 66.882 52.0834C66.4699 51.4667 66.25 50.7417 66.25 50C66.25 49.0054 66.6451 48.0516 67.3484 47.3484C68.0516 46.6451 69.0054 46.25 70 46.25Z"
        fill="#B9DDDF"/>
      <path
        d="M64.6587 65.86L76.8625 78.0638C77.7371 77.919 78.5321 77.469 79.1065 76.7938C79.6808 76.1185 79.9974 75.2616 80 74.375V70.5188L69.9999 60.5188L64.6587 65.86Z"
        fill="#B9DDDF"/>
      <path
        d="M49.5312 54.2676L41.875 61.9238V74.3751C41.875 75.3696 42.2701 76.3235 42.9734 77.0267C43.6766 77.73 44.6304 78.1251 45.625 78.1251H73.3887L49.5312 54.2676Z"
        fill="#B9DDDF"/>
      <path
        d="M56.875 1.875H3.75C2.75544 1.875 1.80161 2.27009 1.09835 2.97335C0.395088 3.67661 0 4.63044 0 5.625L0 59.8438C0 60.8383 0.395088 61.7921 1.09835 62.4954C1.80161 63.1987 2.75544 63.5938 3.75 63.5938H56.875C57.8696 63.5938 58.8234 63.1987 59.5266 62.4954C60.2299 61.7921 60.625 60.8383 60.625 59.8438V5.625C60.625 4.63044 60.2299 3.67661 59.5266 2.97335C58.8234 2.27009 57.8696 1.875 56.875 1.875ZM58.125 59.8438C58.125 60.1753 57.9933 60.4932 57.7589 60.7276C57.5245 60.9621 57.2065 61.0938 56.875 61.0938H3.75C3.41848 61.0938 3.10054 60.9621 2.86612 60.7276C2.6317 60.4932 2.5 60.1753 2.5 59.8438V15.1562H58.125V59.8438ZM58.125 12.6562H2.5V5.625C2.5 5.29348 2.6317 4.97554 2.86612 4.74112C3.10054 4.5067 3.41848 4.375 3.75 4.375H56.875C57.2065 4.375 57.5245 4.5067 57.7589 4.74112C57.9933 4.97554 58.125 5.29348 58.125 5.625V12.6562Z"
        fill="#121E30"/>
      <path
        d="M43.125 31.4062H53.125C53.4565 31.4062 53.7745 31.2746 54.0089 31.0401C54.2433 30.8057 54.375 30.4878 54.375 30.1562V20.1562C54.375 19.8247 54.2433 19.5068 54.0089 19.2724C53.7745 19.0379 53.4565 18.9062 53.125 18.9062H43.125C42.7935 18.9062 42.4755 19.0379 42.2411 19.2724C42.0067 19.5068 41.875 19.8247 41.875 20.1562V30.1562C41.875 30.4878 42.0067 30.8057 42.2411 31.0401C42.4755 31.2746 42.7935 31.4062 43.125 31.4062ZM44.375 21.4062H51.875V28.9062H44.375V21.4062Z"
        fill="#121E30"/>
      <path
        d="M43.125 36.25H53.125C53.4565 36.25 53.7745 36.1183 54.0089 35.8839C54.2433 35.6495 54.375 35.3315 54.375 35C54.375 34.6685 54.2433 34.3505 54.0089 34.1161C53.7745 33.8817 53.4565 33.75 53.125 33.75H43.125C42.7935 33.75 42.4755 33.8817 42.2411 34.1161C42.0067 34.3505 41.875 34.6685 41.875 35C41.875 35.3315 42.0067 35.6495 42.2411 35.8839C42.4755 36.1183 42.7935 36.25 43.125 36.25Z"
        fill="#121E30"/>
      <path
        d="M26.875 31.4062H36.875C37.2065 31.4062 37.5245 31.2746 37.7589 31.0401C37.9933 30.8057 38.125 30.4878 38.125 30.1562V20.1562C38.125 19.8247 37.9933 19.5068 37.7589 19.2724C37.5245 19.0379 37.2065 18.9062 36.875 18.9062H26.875C26.5435 18.9062 26.2255 19.0379 25.9911 19.2724C25.7567 19.5068 25.625 19.8247 25.625 20.1562V30.1562C25.625 30.4878 25.7567 30.8057 25.9911 31.0401C26.2255 31.2746 26.5435 31.4062 26.875 31.4062ZM28.125 21.4062H35.625V28.9062H28.125V21.4062Z"
        fill="#121E30"/>
      <path
        d="M26.875 36.25H36.875C37.2065 36.25 37.5245 36.1183 37.7589 35.8839C37.9933 35.6495 38.125 35.3315 38.125 35C38.125 34.6685 37.9933 34.3505 37.7589 34.1161C37.5245 33.8817 37.2065 33.75 36.875 33.75H26.875C26.5435 33.75 26.2255 33.8817 25.9911 34.1161C25.7567 34.3505 25.625 34.6685 25.625 35C25.625 35.3315 25.7567 35.6495 25.9911 35.8839C26.2255 36.1183 26.5435 36.25 26.875 36.25Z"
        fill="#121E30"/>
      <path
        d="M26.875 52.5H36.875C37.2065 52.5 37.5245 52.3683 37.7589 52.1339C37.9933 51.8995 38.125 51.5815 38.125 51.25V41.25C38.125 40.9185 37.9933 40.6005 37.7589 40.3661C37.5245 40.1317 37.2065 40 36.875 40H26.875C26.5435 40 26.2255 40.1317 25.9911 40.3661C25.7567 40.6005 25.625 40.9185 25.625 41.25V51.25C25.625 51.5815 25.7567 51.8995 25.9911 52.1339C26.2255 52.3683 26.5435 52.5 26.875 52.5ZM28.125 42.5H35.625V50H28.125V42.5Z"
        fill="#121E30"/>
      <path
        d="M26.875 57.3438H36.875C37.2065 57.3438 37.5245 57.2121 37.7589 56.9776C37.9933 56.7432 38.125 56.4253 38.125 56.0938C38.125 55.7622 37.9933 55.4443 37.7589 55.2099C37.5245 54.9754 37.2065 54.8438 36.875 54.8438H26.875C26.5435 54.8438 26.2255 54.9754 25.9911 55.2099C25.7567 55.4443 25.625 55.7622 25.625 56.0938C25.625 56.4253 25.7567 56.7432 25.9911 56.9776C26.2255 57.2121 26.5435 57.3438 26.875 57.3438Z"
        fill="#121E30"/>
      <path
        d="M7.5 57.3438H20.625C20.9565 57.3438 21.2745 57.2121 21.5089 56.9776C21.7433 56.7432 21.875 56.4253 21.875 56.0938V20.1562C21.875 19.8247 21.7433 19.5068 21.5089 19.2724C21.2745 19.0379 20.9565 18.9062 20.625 18.9062H7.5C7.16848 18.9062 6.85054 19.0379 6.61612 19.2724C6.3817 19.5068 6.25 19.8247 6.25 20.1562V56.0938C6.25 56.4253 6.3817 56.7432 6.61612 56.9776C6.85054 57.2121 7.16848 57.3438 7.5 57.3438ZM8.75 21.4062H19.375V54.8438H8.75V21.4062Z"
        fill="#121E30"/>
    </g>
    <defs>
      <clipPath id="clip1">
        <rect width="80" height="80" fill="white"/>
      </clipPath>
    </defs>
  </Svg>
}


const Svg = styled.svg`
  @media (max-width: 600px){
    width: 54px;
    height: 54px;
  }
`;
