import styled from "astroturf"
import React from "react"
import { IconArrowRigth } from "../../icon/iconArrowRigth"
import { GatsbyPageServicesAnalytic } from "../../images/pageServices/GatsbyPageServicesAnalytic"
import { IconPoint } from "../../icon/pageServices/iconPoint"

export const SectionAnalyticsAndDesign = () => {
  return <Section id={"analytics"}>
    <Container className={"container"}>
      <ImageContainer>
        <GatsbyPageServicesAnalytic/>
        <MobileBackgroundCube/>
      </ImageContainer>

      <div>
        <Title>Аналитика и Дизайн</Title>
        <DescriptionContainer>
          <IconPoint/>
          <span>Бизнес-аналитика</span><br/>
          Соберем и проанализируем все требования к проекту, оценим риски. Составим ТЗ, детальную смету и план разработки.<br/>
          <a href={"/Service/Analytics/"}>Подробнее <IconArrowRigth color={"#2669CC"} width={"40px"}/></a>
        </DescriptionContainer>
        <DescriptionContainer>
          <IconPoint/>
          <span>UX / UI</span><br/>
          Наши юзабилити-эксперты и дизайнеры сделают сложный интерфейс простым, удобным и красивым.<br/>
          <a href={"/Service/Design/"}>Подробнее <IconArrowRigth color={"#2669CC"} width={"40px"}/></a>
        </DescriptionContainer>
      </div>
    </Container>
    <BackgroundCube/>
  </Section>
}

const Section = styled.section`
  position: relative;
  margin-top: 100px;
  padding: 85px 0;
  
  @media (max-width: 991px){
    margin-top: 0;
    padding-bottom: 0;
  }
  
  @media (max-width: 420px){
    padding: 55px 0 0 0;
  }
`
const BackgroundCube = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  z-index: -1;
  width: 35vw;
  height: 100%;
  background: #2669CC;
  opacity: 0.05;
  
  @media (max-width: 991px){
    display: none;
  }
`

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 80px;
  align-items: center;
  
  @media (max-width: 991px){
    grid-template-columns: 1fr;
    grid-row-gap: 40px;
    align-items: unset;
    
    img{
      height: 320px;
      margin: auto;
    }
  }
  
  @media (max-width: 600px){
    display: flex;
    flex-direction: column-reverse;
    
    img{
      height: auto;
      width: 100%;
    }
  }
`

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  :global(.gatsby-image-wrapper) {
    width: 100%;
  }

  @media (max-width: 991px){
    justify-content: center;

    :global(.gatsby-image-wrapper) {
      width: 420px;
    }
  }
  
  @media (max-width: 600px){
    :global(.gatsby-image-wrapper) {
      width: 100%;
    }
  }
`

const MobileBackgroundCube = styled.div`
position: absolute;
  background: #2669CC;
  opacity: 0.05;
  top: 0;
  bottom: 0;
  right: -30px;
  left: -30px;
  
  @media(min-width: 992px){
    display: none;
  }
`

const Title = styled.h1`
  font-family: "Gilroy", sans-serif;
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
  color: #121E30;
  
  margin-bottom: 50px;
  
  @media (max-width: 600px){
    font-size: 30px;
    line-height: 36px;
  }
  
  @media (max-width: 355px){
    font-size: 26px;
  }
`

const DescriptionContainer = styled.div`
  position: relative;
  font-family: "Gilroy", sans-serif;
  font-size: 24px;
  line-height: 36px;
  color: #121E30;
  
  margin-bottom: 30px;
  
  span{
    font-weight: 500;
    font-size: 28px;
    margin-bottom: 12px;
  }
  a{
    cursor: pointer;
    font-family: "Gilroy", sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #2669CC;
    display: flex;
    align-items: center;
    margin-top: 10px;
    width: fit-content;
    
    svg {
      margin-left: 10px;
    }
  }
  
  @media (max-width: 600px){
    font-size: 18px;
    line-height: 24px;
    
    span {
      font-size: 24px;
      line-height: 36px;
    }
  }
  
  padding-left: 44px;
`
