import React from "react"
import styled from "astroturf"

export const IconPoint = () => {
  return <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11.75" stroke="#D2D5DA" strokeWidth="0.5"/>
    <circle cx="12" cy="12" r="11.75" stroke="#D2D5DA" strokeWidth="0.5"/>
    <circle cx="12" cy="12" r="6" fill="#D2D5DA"/>
  </Svg>
}

const Svg = styled.svg`
  position: absolute;
  left: 0;
  top: 5px;
`
